export const Desc = {
  _type: "GridWidget",
  identifier: "main_description",
  startRow: 1,
  endRow: 2,
  startColumn: 1,
  endColumn: 2,
  options: {
    name: "Добро пожаловать в Систему управления проектами Атом!",
  },
};

export const New = {
  _type: "GridWidget",
  identifier: "news",
  startRow: 2,
  endRow: 3,
  startColumn: 1,
  endColumn: 2,
  options: {
    name: "Новости",
  },
};

export const WorkTable = {
  _type: "GridWidget",
  identifier: "work_packages_table",
  startRow: 1,
  endRow: 3,
  startColumn: 2,
  endColumn: 3,
  options: {
    name: "Проекты",  
    queryId: 766     // tech  public = true
  },
};

export const Grid = {
  _type: "Grid",
  id: 1,
  rowCount: 2,
  columnCount: 2,
  options: {},
  startColumn: 1,
  widgets: {},
};
