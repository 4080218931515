import { WidgetIdentity } from "./grid.component";

export const listWidgets =
  [{ id: 'documents', name: 'Документы' },
  { id: 'time_entries_current_user', name: 'Затраченное мной время' },
  { id: 'project_favorites', name: 'Избранные проекты' },
  { id: 'work_packages_calendar', name: 'Календарь' },
  { id: 'team', name: 'Календарь' },
  { id: 'news', name: 'Новости' },
  { id: 'work_packages_overview', name: 'Обзор пакетов работ' },
  { id: 'work_packages_accountable', name: 'Пакеты работ, в которых я подотчетен' },
  { id: 'work_packages_watched', name: 'Пакеты работ, просмотренные мной' },
  { id: 'work_packages_created', name: 'Пакеты работ, созданные мной' },
  { id: 'work_packages_assigned', name: 'Переданные мне пакеты работ' },
  { id: 'custom_text', name: 'Пользовательский текст' },
  { id: 'meetings', name: 'Совещания и поручения' },
  { id: 'projects_list', name: 'Список проектов' },
  { id: 'work_packages_table', name: 'Таблица пакетов работ' },
  ] as WidgetIdentity[];

export const typeKP = 'КП';
export const statusEnd = 'Завершён';
export const statusClose = 'Прекращён';
export const columnsProjects = ['id', 'subject', 'priority', 'startDate', 'factStartDate', 'dueDate', 'factStartDate', 'factDueDate', 'duration', 'factDuration'];

export const statusStart = 'Начата';
export const columnsStage = ['id', 'subject', 'dueDate'];

// Worker
export let workerProjects = {
  config:
  {
    _type: "GridWidget",
    identifier: "work_packages_table",
    startColumn: 1,
    startRow: 1,
    endRow: 2,
    endColumn: 2,
    options: {
      name: "Проекты",
      queryId: '',
    },
  }
};

export let workerStage = {
  config:
  {
    _type: "GridWidget",
    identifier: "work_packages_table",
    startColumn: 1,
    startRow: 2,
    endRow: 3,
    endColumn: 2,
    options: {
      name: "Основные вехи по проектам",
      queryId: '',
    },
  }
};

export let workerConference = {
  config:
  {
    _type: "GridWidget",
    identifier: "meetings",
    startColumn: 1,
    startRow: 3,
    endRow: 4,
    endColumn: 2,
    options: {
      name: "Совещания и поручения",
    },
  }
};

// Manager
export let managerProjects = {
  config:
  {
    _type: "GridWidget",
    identifier: "projects_list",
    startColumn: 1,
    startRow: 1,
    endRow: 2,
    endColumn: 2,
    options: {
      name: "Список проектов",
    },
  }
};

export let managerTeam = {
  config:
  {
    _type: "GridWidget",
    identifier: "team",
    startColumn: 2,
    startRow: 1,
    endRow: 2,
    endColumn: 3,
    options: {
      name: "Команда",
    },
  },
  gap: { type: 'column' }
};


export let managerStageI2P = {
  config:
  {
    _type: "GridWidget",
    identifier: "work_packages_table",
    startColumn: 1,
    startRow: 2,
    endRow: 3,
    endColumn: 2,
    options: {
      name: "Количество проектов в работе I2P",
      queryId: '',
    },
  }
};

export let managerStageP2I = {
  config:
  {
    _type: "GridWidget",
    identifier: "work_packages_table",
    startColumn: 1,
    startRow: 3,
    endRow: 5,
    endColumn: 2,
    options: {
      name: "Количество проектов в работе P2I",
      queryId: '',
    },
  }
};

export let managerConference = {
  config:
  {
    _type: "GridWidget",
    identifier: "meetings",
    startColumn: 1,
    startRow: 5,
    endRow: 6,
    endColumn: 2,
    options: {
      name: "Совещания и поручения",
    },
  }
};

export const managerStageStatus = ['В работе план','В работе отставание','В работе ошибка'];
export const managerStage = ['id', 'subject'];
export const workerWidgets = [workerProjects, workerStage, workerConference];
export const managerWidgets = [managerProjects, managerTeam, managerStageI2P, managerStageP2I, managerConference];