import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IHALCollection } from 'core-app/core/apiv3/types/hal-collection.type';
import { CurrentProjectService } from 'core-app/core/current-project/current-project.service';
import { I18nService } from 'core-app/core/i18n/i18n.service';
import { PathHelperService } from 'core-app/core/path-helper/path-helper.service';
import { IProject } from 'core-app/core/state/projects/project.model';
import { IFilterAssigned, ITeam } from 'core-app/core/state/team-plan/teams';
import { AbstractWidgetComponent } from 'core-app/shared/components/grids/widgets/abstract-widget.component';
import { firstValueFrom, Subject, Subscription } from 'rxjs';
import { Events, Item, Period, Section } from './lib/new-time-scheduler.model';
import moment from 'moment';
import { NewTimeSchedulerService } from './lib/new-time-scheduler.service';
import { WorkPackageCollectionResource } from 'core-app/features/hal/resources/wp-collection-resource';
import { WorkPackageResource } from 'core-app/features/hal/resources/work-package-resource';
import { StateService } from '@uirouter/core';
import { QueryResource } from 'core-app/features/hal/resources/query-resource';
import { QueryPropsFilter, UrlParamsHelperService } from 'core-app/features/work-packages/components/wp-query/url-params-helper';
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';
import { ID } from '@datorama/akita';
@Component({
  templateUrl: './wp-team.component.html',
  styleUrls: ['./wp-team.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetWpTeamComponent extends AbstractWidgetComponent implements OnInit, OnDestroy {
  public projects: IProject[];
  public teams: ITeam[];
  public timeline: any;

  private subProjects: Subscription;
  private subTeams: Subscription;
  private subTimeline: Subscription;

  public selectedProject$ = new Subject<string>();
  public selectedTeam$ = new Subject<ITeam>();
  public selectedFilters$ = new Subject<string>();

  public selectedProject: string;
  public selectedTeam: ITeam;

  public events: Events = new Events();
  public periods: Period[];
  public sections: Section[];
  public items: Item[];

  public showTimeline = false;

  public itemCount = 3;
  public sectionCount = 10;

  constructor(
    private readonly http: HttpClient,
    protected readonly i18n: I18nService,
    protected readonly injector: Injector,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly pathHelperService: PathHelperService,
    protected readonly currentProject: CurrentProjectService,
    protected readonly $state: StateService,
    protected readonly schedulerService: NewTimeSchedulerService,
    protected readonly urlParamsHelper: UrlParamsHelperService,
    protected readonly apiV3Service: ApiV3Service,
  ) {
    super(i18n, injector);
  }

  initCalendar(): void {
    this.periods = [
      {
        name: '3 дня',
        timeFramePeriod: (60 * 3),
        timeFrameOverall: (60 * 24 * 3),
        timeFrameHeaders: [
          'MMMM',
          'HH'
        ],
        classes: 'period-3day',
      },
      {
        name: '1 неделя',
        timeFrameHeaders: ['MMMM YYYY', 'ddd DD'],
        classes: '',
        timeFrameOverall: 1440 * 7,
        timeFramePeriod: 1440,
      },
      {
        name: '2 недели',
        timeFrameHeaders: ['MMMM YYYY', 'ddd DD'],
        timeFrameHeadersTooltip: ['MMMM YYYY', 'dd DD'],
        classes: '',
        timeFrameOverall: 1440 * 14,
        timeFramePeriod: 1440,
      }];
    this.sections = [];
    this.items = [];
  }

  ngOnInit(): void {
    this.subProjects = this.http.get<IHALCollection<IProject>>(this.pathHelperService.apiV3Projects, this.pathHelperService.httpHeadersJson).subscribe(
      (projects) => {
        this.projects = projects._embedded.elements;
        this.selectedProject$.next(this.projects[0].identifier);
        this.cdr.detectChanges();
      },
    );
    this.selectedProject$.subscribe((selected) => { this.loadTeams(selected); this.selectedProject = selected });
    this.selectedTeam$.subscribe((team) => { this.loadTimeline(team); this.selectedTeam = team; });
    this.initCalendar();
  }

  refresh() {
    this.sections = [];
    this.items = [];
    this.showTimeline = true;
  }

  selectTeam(team: any): void {
    this.selectedTeam$.next(team);
  }

  loadTeams(projectIdentifier: string): void {
    this.showTimeline = false;
    this.subTeams = this.http.get<ITeam[]>(this.pathHelperService.getTeamsByProjectId(projectIdentifier))
      .subscribe({
        next: (teams) => {
          this.teams = teams;
          this.teams.forEach((team) => { team.users = this.findCountUsers(team); team.created_at = moment(team.created_at).format("DD.MM.YYYY"); });
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log('loadTeams', err);
          this.teams = [];
        },
        complete: () => {
          console.log('loadTeams', true);
        }
      });
  }

  public generateQueryProps(
    query: QueryResource,
    startDate: string,
    endDate: string,
  ): string {
    return this.urlParamsHelper.encodeQueryJsonParams(
      query,
      (props) => ({
        ...props,
        pp: 500,
        pa: 1,
        f: [
          ...props.f.filter((filter) => filter.n !== 'datesInterval'),
          this.dateFilter(startDate, endDate),
        ],
      }),
    );
  }

  dateFilter(startDate: string, endDate: string): QueryPropsFilter {
    return { n: 'datesInterval', o: '<>d', v: [startDate, endDate] };
  }

  async loadTimeline(item: ITeam): Promise<void> {
    this.refresh();

    const initialQuery = await firstValueFrom(this.apiV3Service.queries.find({ pageSize: 0 }, item.id.toString()));
    const queryProps = this.generateQueryProps(
      initialQuery,
      "01-01-2000",
      "01-01-2099"
    );

    const decodedProps = this.getCurrentQueryProps(queryProps);
    const queryData = this.urlParamsHelper.buildV3GetQueryFromJsonParams(decodedProps);

    this.subTimeline = this
      .apiV3Service
      .queries
      .find(queryData, item.id.toString())
      .subscribe(
        (timeline) => {
          this.timeline = timeline;
          const wpResults = this.timeline.$source._embedded.results as WorkPackageCollectionResource;
          this.setSections(wpResults);
          this.setItems(wpResults, item.id);
          this.cdr.detectChanges();
        },
      );
  }

  getCurrentQueryProps(query_props?: string | null): string | null {
    if (query_props) {
      return decodeURIComponent(query_props);
    }
    return null;
  }

  setSections(wpResource: WorkPackageCollectionResource): void {
    wpResource._embedded.elements.forEach((wp: WorkPackageResource) => {
      if (this.sections.filter((section) => section.name == wp._links.assignee.title).length == 0) {
        this.schedulerService.sectionPush({ id: this.getPartLastSlash(wp._links.assignee.href), name: wp._links.assignee.title })
      }
    });
  }

  setItems(wpResource: WorkPackageCollectionResource, queryId: ID): void {
    wpResource._embedded.elements.forEach((wp: WorkPackageResource) => {
      const item = {
        id: parseInt(wp.id!),
        sectionId: this.getPartLastSlash(wp._links.assignee.href),
        queryId: queryId,
        name: wp.subject,
        start: moment(wp.startDate, 'YYYY-MM-DD'),
        end: moment(wp.dueDate, 'YYYY-MM-DD').add(1, 'day'),
        classes: '',
        type: wp._links.type,
        status: wp._links.status,
        task: wp._links.self,
        project: { title: wp._links.project.title, href: this.getPartLastSlash(wp._links.project.href).toString() },
        formatDate: { start: moment(wp.startDate).format('MMM DD, YYYY'), end: moment(wp.dueDate).format('MMM DD, YYYY') }
      } as Item;
      this.schedulerService.itemPush(item);
    });
  }

  findCountUsers(team: ITeam): number {
    const target = team.filters.find((filter: IFilterAssigned) => filter.assigned_to_id);
    return target != undefined ? target.assigned_to_id.values.length : 0;
  }

  getPartLastSlash(inputString: string): number {
    const parts = inputString.split('/');
    return parseInt(parts[parts.length - 1]);
  }

  onOptionChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedProject$.next(selectElement.value);
  }

  ngOnDestroy(): void {
    this.subProjects.unsubscribe();
    this.subTeams.unsubscribe();

    this.selectedProject$.unsubscribe();
    this.selectedTeam$.unsubscribe();
    this.selectedFilters$.unsubscribe();
  }
}
