<widget-header
  [name]="widgetName"
  [editable]="isEditable">

  <attribute-help-text slot="prepend"
                       attribute="description"
                       [attributeScope]="'Project'"></attribute-help-text>

  <widget-menu
      slot="menu"
      [resource]="resource">
  </widget-menu>
</widget-header>

<div class="grid--widget-content -allow-inner-overflow row">
  <div class="column">
    <a href="{{_linkVideo}}">Видеоинструкция</a>
    </div>
  <div class="column">
    <a href="{{_linkTech}}">Написать в техподдержку</a>
    </div>
</div>
