import { Component } from "@angular/core";
import { GridPageComponent } from "core-app/shared/components/grids/grid/page/grid-page.component";
import { GRID_PROVIDERS } from "core-app/shared/components/grids/grid/grid.component";
import { GridResource } from "core-app/features/hal/resources/grid-resource";
import { GridWidgetResource } from "core-app/features/hal/resources/grid-widget-resource";
import { Desc, Grid, New, WorkTable } from "./config-main-widget";

@Component({
  selector: "main-screen",
  templateUrl: "../grid/page/grid-page.component.html",
  styleUrls: ["../grid/page/grid-page.component.sass"],
  providers: GRID_PROVIDERS,
})
export class MainScreenComponent extends GridPageComponent {
  public text = {
    title: "Атом",
    html_title: "Атом",
  };
  private _workTable: GridWidgetResource;
  private _new: GridWidgetResource;
  private _desc: GridWidgetResource;
  private halResService = this.gridInitialization.halResourceService;

  ngOnInit() {
    this.renderer.addClass(document.body, "widget-grid-layout");

    this._workTable = this.halResService.createHalResourceOfClass(GridWidgetResource, WorkTable);
    this._new = this.halResService.createHalResourceOfClass(GridWidgetResource, New);
    this._desc = this.halResService.createHalResourceOfClass(GridWidgetResource, Desc);

    Grid.widgets = [this._workTable, this._new, this._desc];
    this.grid = this.halResService.createHalResourceOfClass(GridResource, Grid, true);   
  
    this._setHtmlTitle();
  }

  private _setHtmlTitle() {
    this.title.setTitle(this.text.html_title);
  }

  protected i18nNamespace(): string {
    return "main_screen";
  }

  protected gridScopePath(): string {
    return this.pathHelper.mainScreenPath();
  }
}
