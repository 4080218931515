import { ChangeDetectorRef, Component, ComponentRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { GridResource } from 'core-app/features/hal/resources/grid-resource';
import { DomSanitizer } from '@angular/platform-browser';
import { GridWidgetsService } from 'core-app/shared/components/grids/widgets/widgets.service';
import { AbstractWidgetComponent } from 'core-app/shared/components/grids/widgets/abstract-widget.component';
import { GridArea } from 'core-app/shared/components/grids/areas/grid-area';
import { GridMoveService } from 'core-app/shared/components/grids/grid/move.service';
import { GridDragAndDropService } from 'core-app/shared/components/grids/grid/drag-and-drop.service';
import { GridResizeService } from 'core-app/shared/components/grids/grid/resize.service';
import { GridAreaService } from 'core-app/shared/components/grids/grid/area.service';
import { GridAddWidgetService } from 'core-app/shared/components/grids/grid/add-widget.service';
import { GridRemoveWidgetService } from 'core-app/shared/components/grids/grid/remove-widget.service';
import { WidgetWpGraphComponent } from 'core-app/shared/components/grids/widgets/wp-graph/wp-graph.component';
import { GridWidgetArea } from 'core-app/shared/components/grids/areas/grid-widget-area';
import { BrowserDetector } from 'core-app/core/browser/browser-detector.service';
import { combineLatest, map, Observable, switchMap, take } from 'rxjs';
import { GridWidgetResource } from 'core-app/features/hal/resources/grid-widget-resource';
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';
import { workerProjects, workerStage, columnsProjects, statusClose, statusEnd, typeKP, statusStart, columnsStage, managerStageI2P, managerStageP2I, managerWidgets, workerWidgets, managerStageStatus, managerStage } from './config-lk-widget';
import { HalResourceService } from 'core-app/features/hal/services/hal-resource.service';
import { StatusResource } from 'core-app/features/hal/resources/status-resource';
import { CollectionResource } from 'core-app/features/hal/resources/collection-resource';
import { TypeResource } from 'core-app/features/hal/resources/type-resource';
import { QueryResource } from 'core-app/features/hal/resources/query-resource';
import { StateService } from '@uirouter/core';
import { GridGap } from '../areas/grid-gap';

export interface WidgetRegistration {
  identifier: string;
  title: string;
  component: { new(...args: any[]): AbstractWidgetComponent };
  properties?: Record<string, unknown>;
}

export const GRID_PROVIDERS = [
  GridAreaService,
  GridMoveService,
  GridDragAndDropService,
  GridResizeService,
  GridAddWidgetService,
  GridRemoveWidgetService,
];

export interface WidgetIdentity {
  id: string,
  name: string
}

export interface WidgetConfig {
  area: GridWidgetArea,
  gap?: GridGap
}

@Component({
  templateUrl: './grid.component.html',
  selector: 'grid',
})
export class GridComponent implements OnDestroy, OnInit {
  public uiWidgets: ComponentRef<any>[] = [];

  public GRID_AREA_HEIGHT = 'auto';

  public GRID_GAP_DIMENSION = '20px';

  public component = WidgetWpGraphComponent;

  @Input() grid: GridResource;

  public workerQueryProjects: { [key: string]: any };
  public workerQueryStage: { [key: string]: any };

  public managerQueccryI2P: { [key: string]: any };
  public managerQueryPI2: { [key: string]: any };

  public types$: Observable<CollectionResource<TypeResource>>;
  public statuses$: Observable<CollectionResource<StatusResource>>;
  public customs$: Observable<CollectionResource<any>>;

  public typeIds: number[] = [];
  public statusIds: number[] = [];
  public statusStartId: number;
  public statusByStage: number[] = [];

  private queryProjects$: Observable<QueryResource>;
  private queryStage$: Observable<QueryResource>;

  private managerQueryI2P$: Observable<QueryResource>;
  private managerQueryPI2$: Observable<QueryResource>;

  constructor(private sanitization: DomSanitizer,
    private widgetsService: GridWidgetsService,
    public drag: GridDragAndDropService,
    public resize: GridResizeService,
    public layout: GridAreaService,
    public add: GridAddWidgetService,
    public remove: GridRemoveWidgetService,
    private apiV3Service: ApiV3Service,
    private halResService: HalResourceService,
    private state: StateService,
    readonly browserDetector: BrowserDetector,
    readonly cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.layout.gridResource = this.grid;
    this.apiV3Service.users.getCurrentUserIsManeger().subscribe((flag) => this.getConfigWidgets(flag));
  }

  getConfigWidgets(isManager: boolean): void {
    this.types$ = this.apiV3Service.types.get().pipe(take(1));
    this.statuses$ = this.apiV3Service.statuses.get().pipe(take(1));
    isManager = true;

    combineLatest([this.types$, this.statuses$]).subscribe({
      next: ([types, statuses]) => {
        this.getTypes(types);
        this.getStatus(statuses);
        isManager ? this.getManagerWidgets() : this.getWorkerWidgets();
      },
      error: (error) => {
        console.error('Error fetching data types, statuses:', error);
      },
    });
  }

  private getTypes(types: CollectionResource<TypeResource>) {
    this.typeIds = types.elements
      .filter((type) => type.name === typeKP)
      .map((item) => item.$source.id) as number[];
  }

  private getStatus(statuses: CollectionResource<StatusResource>) {
    this.statusIds = statuses.elements
      .filter((status) => status.name === statusEnd || status.name === statusClose)
      .map((item) => item.$source.id) as number[];

    this.statusStartId = statuses.elements
      .filter((status) => status.name === statusStart)
      .map((item) => item.$source.id)[0] as number;

    this.statusByStage = managerStageStatus
      .reduce((ids: number[], name) => {
        const item = statuses.elements.find(item => item.$source.name === name);
        if (item) {
          ids.push(item.$source.id);
        }
        return ids;
      }, []);
  }

  ngOnDestroy() {
    this.uiWidgets.forEach((widget) => widget.destroy());
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key !== 'Escape') {

    } else if (this.drag.currentlyDragging) {
      this.drag.abort();
    } else if (this.resize.currentlyResizing) {
      this.resize.abort();
    }
  }

  public addWidget(area: GridWidgetArea | GridArea) {
    console.log('test', area)
    void this
      .add
      .widget(area)
      .then(() => this.cdRef.detectChanges());
  }

  private addWidgetList(array: WidgetConfig[]) {
    array.forEach((item) => {
      this.add.widgetNew(item.area, item.gap).then(() => this.cdRef.detectChanges());
    });
  }

  public widgetComponent(area: GridWidgetArea) {
    const { widget } = area;

    if (!widget) {
      return null;
    }

    const registration = this.widgetsService.registered.find((reg) => reg.identifier === widget.identifier);

    if (!registration) {
      // debugLog(`No widget registered with identifier ${widget.identifier}`);

      return null;
    }
    return registration.component;
  }

  public widgetComponentInput(area: GridWidgetArea) {
    return { resource: area.widget };
  }

  public widgetComponentOutput(area: GridWidgetArea) {
    return { resourceChanged: this.layout.saveWidgetChangeset.bind(this.layout) };
  }

  public get gridColumnStyle() {
    return this.gridStyle(this.layout.numColumns,
      `calc((100% - ${this.GRID_GAP_DIMENSION} * ${this.layout.numColumns + 1}) / ${this.layout.numColumns})`);
  }

  public get gridRowStyle() {
    return this.gridStyle(this.layout.numRows,
      this.GRID_AREA_HEIGHT);
  }

  public identifyGridArea(index: number, area: GridArea) {
    return area.guid;
  }

  public get isHeadersDisplayed() {
    return this.layout.isEditable;
  }

  public get isMobileDevice() {
    return this.browserDetector.isMobile;
  }

  private gridStyle(amount: number, itemStyle: string) {
    let style = '';
    for (let i = 0; i < amount; i++) {
      style += `${this.GRID_GAP_DIMENSION} ${itemStyle} `;
    }
    style += `${this.GRID_GAP_DIMENSION}`;
    return this.sanitization.bypassSecurityTrustStyle(style);
  }

  private get propsProjects(): { [key: string]: any } {
    const filters: any[] = [];

    filters.push({ type: { operator: '=', values: this.typeIds } });
    filters.push({ status: { operator: '!', values: this.statusIds } })

    this.workerQueryProjects = {
      'columns[]': columnsProjects,
      filters: JSON.stringify(filters),
      sortBy: JSON.stringify([['id', 'desc']]),
      showHierarchies: true,
    };
    return this.workerQueryProjects;
  }

  private get propsStage(): { [key: string]: any } {
    const filters: any[] = [];

    filters.push({ status: { operator: '=', values: [this.statusStartId], } })
    filters.push({ dueDate: { operator: '<t+', values: [15], } });

    this.workerQueryStage = {
      'columns[]': columnsStage,
      filters: JSON.stringify(filters),
      sortBy: JSON.stringify([['id', 'desc']]),
      groupBy: 'project'
    };
    return this.workerQueryStage;
  }

  private get propsI2P(): { [key: string]: any } {
    const filters: any[] = [];

    filters.push({ type: { operator: '=', values: this.typeIds } });
    filters.push({ status: { operator: '=', values: this.statusByStage } })

    this.workerQueryProjects = {
      'columns[]': managerStage,
      filters: JSON.stringify(filters),
      sortBy: JSON.stringify([['id', 'desc']]),
      //groupBy: ''
    };
    return this.workerQueryProjects;
  }

  private get propsP2I(): { [key: string]: any } {
    const filters: any[] = [];

    filters.push({ type: { operator: '=', values: this.typeIds } });
    filters.push({ status: { operator: '=', values: this.statusByStage } })

    this.workerQueryStage = {
      'columns[]': managerStage,
      filters: JSON.stringify(filters),
      sortBy: JSON.stringify([['id', 'desc']]),
      //groupBy: ''
    };
    return this.workerQueryStage;
  }

  private getWorkerWidgets() {
    if (this.grid.widgets.length == 0) {
      this.workerWidgetsCreate();
    }
    else if (this.grid.widgets.length == 2) {
      this.remove.removeWidgets();
      window.location.reload();
    }
  }

  private getManagerWidgets() {
    if (this.grid.widgets.length == 0) {
      this.managerWidgetsCreate();
    }
    else if (this.grid.widgets.length == 2) {
      this.remove.removeWidgets();
      window.location.reload();
    }
  }


  private workerWidgetsCreate() {
    this.queryProjects$ = this.createQuery(this.propsProjects).pipe(take(1));
    this.queryStage$ = this.createQuery(this.propsStage).pipe(take(1));

    combineLatest([this.queryProjects$, this.queryStage$]).subscribe({
      next: ([queryProject, queryStage]) => {
        workerProjects.config.options.queryId = queryProject.$source.id;
        workerStage.config.options.queryId = queryStage.$source.id;
        this.addWorkerWidgets();
      },
      complete: () => {
        this.layout.rebuildAndPersist();
      },
      error: (error) => {
        console.error('Error fetching worker widgets:', error);
      },
    });
  }

  private managerWidgetsCreate() {
    this.managerQueryI2P$ = this.createQuery(this.propsI2P).pipe(take(1));
    this.managerQueryPI2$ = this.createQuery(this.propsP2I).pipe(take(1));

    combineLatest([this.managerQueryI2P$, this.managerQueryPI2$]).subscribe({
      next: ([queryI2P, queryPI2]) => {
        managerStageI2P.config.options.queryId = queryI2P.$source.id;
        managerStageP2I.config.options.queryId = queryPI2.$source.id;
        this.addManagerWidgets();
      },
      complete: () => {
        this.layout.rebuildAndPersist();
      },
      error: (error) => {
        console.error('Error fetching manager widgets:', error);
      },
    });
  }

  private addWorkerWidgets() {
    const settings = this.createConfigWidgets(workerWidgets);
    this.addWidgetList(settings);
  }

  private addManagerWidgets() {
    const settings = this.createConfigWidgets(managerWidgets);
    this.addWidgetList(settings);
  }

  private createConfigWidgets(array: any[]): WidgetConfig[] {
    return array.map((widget) => {
      const resurce = this.halResService.createHalResourceOfClass(GridWidgetResource, widget.config);
      const area = new GridWidgetArea(resurce);
      const gap = widget.gap ? new GridGap(area.startRow, area.endRow, area.startColumn, area.endColumn, widget.gap.type) : undefined;
      return { area: area, gap: gap };
    });
  }

  private createQuery(props: { [key: string]: any }): Observable<QueryResource> {
    const projectIdentifier = this.state.params.projectPath as string;
    const initializationProps = props;
    const queryProps = {
      pageSize: 0,
      ...initializationProps,
    };
    console.log("create queryProps", queryProps);
    return this
      .apiV3Service
      .queries
      .form
      .loadWithParams(
        queryProps,
        undefined,
        projectIdentifier,
        this.queryCreationParams(),
      )
      .pipe(
        switchMap(([form, query]) => this
          .apiV3Service
          .queries
          .post(query, form)
          .pipe(
            map((resource: QueryResource) => {
              return resource;
            }),
          ),
        ),
      );
  }

  protected queryCreationParams() {
    return {
      hidden: false,
      public: false,
    };
  }
}
