<div class="time-sch-wrapper" [style.height]="maxHeight">
  <div class="time-sch-header-wrapper mb-1">
    <div class="time-sch-period-container" *ngIf="showActionButtons">
      <button class="btn" *ngFor="let period of periods" [ngClass]="period.classes" (click)="changePeriod(period)"
        [title]="period.tooltip? period.tooltip : ''">
        {{period.name}}
      </button>
    </div>

    <div class="time-sch-time-container" *ngIf="showActionButtons">
      <button class="btn" *ngIf="showToday" (click)="gotoToday()">{{text.todayButton}}</button>
      <button class="btn" (click)="previousPeriod()"><svg class="arrow" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M1.02698 11.9929L5.26242 16.2426L6.67902 14.8308L4.85766 13.0033L22.9731 13.0012L22.9728 11.0012L4.85309 11.0033L6.6886 9.17398L5.27677 7.75739L1.02698 11.9929Z"
            fill="currentColor" />
        </svg></button>
      <button class="btn" (click)="nextPeriod()"><svg class="arrow" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M23.0677 11.9929L18.818 7.75739L17.4061 9.17398L19.2415 11.0032L0.932469 11.0012L0.932251 13.0012L19.2369 13.0032L17.4155 14.8308L18.8321 16.2426L23.0677 11.9929Z"
            fill="currentColor" />
        </svg></button>
    </div>

    <h3 class="text-center m-0" *ngIf="showHeaderTitle">
      {{ start.format(headerFormat) + ' - ' + end.format(headerFormat)}}
    </h3>
  </div>

  <div class="time-sch-table-wrapper">
    <table class="time-sch-table">
      <tr *ngFor="let timeFrameHeader of header; let i = index; trackBy: trackByFn">

        <td class="time-sch-section text-center" *ngIf="i === 0" [rowSpan]="periods.length">
          {{text.sectionTitle}}
        </td>

        <td class="text-center" *ngFor="let dateTime of timeFrameHeader.headerDetails; trackBy: trackByFn"
          [colSpan]="dateTime.colspan" [title]="dateTime.tooltip? dateTime.tooltip : ''">
          {{dateTime.name}}
        </td>

      </tr>
    </table>

    <div class="time-sch-content-wrap">
      <table class="time-sch-table">
        <tr *ngFor="let sectionItem of sectionItems; trackBy: trackByFn"
          [style.height]="sectionItem.minRowHeight + 'px'">
          <td #section class="time-sch-section text-center"
            (click)="events.SectionClickEvent ? events.SectionClickEvent(sectionItem.section) : false"
            (contextmenu)="events.SectionContextMenuEvent ? events.SectionContextMenuEvent(sectionItem.section, $event) : false"
            [style.cursor]="events.SectionClickEvent ? 'pointer' : ''"
            [title]="sectionItem.section.tooltip? sectionItem.section.tooltip : ''">
            {{sectionItem.section.name}}
          </td>

          <td *ngFor="let td of header[header.length - 1].headerDetails; trackBy: trackByFn"
            class="time-sch-table-cell"></td>
        </tr>
      </table>

      <div class="time-sch-section-wrapper" [style.left]="sectionLeftMeasure" cdkDropListGroup>
        <div class="time-sch-section-container" cdkDropList cdkDropListSortingDisabled
          [cdkDropListData]="sectionItem.section" (cdkDropListDropped)="drop($event)"
          *ngFor="let sectionItem of sectionItems; trackBy: trackByFn" [style.height]="sectionItem.minRowHeight + 'px'">
          <div class="time-sch-item" cdkDrag cdkDragLockAxis="y" cdkDragBoundary=".time-sch-section-wrapper"
            [cdkDragData]="itemMeta.item" [cdkDragDisabled]="!allowDragging"
            *ngFor="let itemMeta of sectionItem.itemMetas" [ngClass]="itemMeta.item.classes"
            (click)="events.ItemClicked ? events.ItemClicked(itemMeta.item) : false"
            (contextmenu)="events.ItemContextMenu ? events.ItemContextMenu(itemMeta.item, $event) : false"
            [style.height]="(minRowHeight - 15) + 'px'" [style.top]="(itemMeta.cssTop + 5) + 'px'"
            [style.left]="itemMeta.cssLeft + '%'" [style.width]="itemMeta.cssWidth + '%'">
            <div class="item-drag-placeholder" *cdkDragPlaceholder [style.height]="(minRowHeight - 20) + 'px'"
              [style.left]="itemMeta.cssLeft + '%'" [style.width]="'calc('+ itemMeta.cssWidth + '% - 6px)'"></div>
            <div class="time-sch-item-start" *ngIf="itemMeta.isStart"></div>
            <div class="time-sch-item-content" [title]="itemMeta.item.tooltip? itemMeta.item.tooltip : ''">
              <div class="row">
                <div class="row-element" [title]="itemMeta.item.status.title">{{itemMeta.item.status.title}}</div>
                <div class="row-element"><span class="link"
                    (click)="linkTask(itemMeta.item.project.href, itemMeta.item.id, itemMeta.item.queryId)"
                    [title]="itemMeta.item.id">#{{itemMeta.item.id}}</span>
                </div>
                <div class="row-element"><span class="link" (click)="linkProject(itemMeta.item.project.href)"
                    [title]="itemMeta.item.project.title">-
                    {{itemMeta.item.project.title}}</span></div>
              </div>
              <div class="row">
                <div class="row-element"><span class="date"
                    [title]="itemMeta.item.formatDate.start">{{itemMeta.item.formatDate.start}}</span></div>
                <div class="row-element"><span class="date" [title]="itemMeta.item.formatDate.end">-
                    {{itemMeta.item.formatDate.end}}</span></div>
                <div class="row-element"><span class="name" [title]="itemMeta.item.name">{{itemMeta.item.name}}</span>
                </div>
              </div>
            </div>
            <div class="time-sch-item-end" *ngIf="itemMeta.isEnd"></div>
          </div>
        </div>

        <div class="time-sch-current-time" *ngIf="showCurrentTime" [title]="currentTimeTitle"
          [style.visibility]="currentTimeVisibility" [style.left]="currentTimeIndicatorPosition"></div>
      </div>

    </div>
  </div>
</div>